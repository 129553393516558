import React from "react";
import "../styles/Panel.css";

const Panel = (props) => {
  return (
    <div
      className="panel"
      onClick={() => {
        props.setShowBio(true);
      }}
    >
      <div
        id={props.showBio ? "background-bio" : "background"}
        style={{ backgroundImage: `url(${props.background}` }}
      ></div>
      <div id="text">
        {!props.showBio && <div id="subtitle">{props.subtitle}</div>}
      </div>
      {!props.showBio && <span className="links">{props.footer}</span>}
    </div>
  );
};

export default Panel;
