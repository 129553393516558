import React, { useState } from "react";
import Panel from "../components/Panel";
import Bio from "../components/Bio";
import {
  balldefender,
  cookulator,
  choihung,
  montoutimer,
  micoquiz,
  shangchi,
  stawamus,
  stawamus_gungfu,
} from "../assets";
import * as Constants from "../constants/Constants";
import "../styles/Carousel.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

const Carousel = () => {
  const [showBio, setShowBio] = useState(false);

  return (
    <>
      {!showBio && (
        <div id="title" onClick={() => setShowBio(true)}>
          RYAN TONG
        </div>
      )}
      <div className="swiper-button image-swiper-button-next">
        <IoIosArrowForward />
      </div>
      <div className="swiper-button image-swiper-button-prev">
        <IoIosArrowBack />
      </div>

      <Swiper
        navigation={{
          nextEl: ".image-swiper-button-next",
          prevEl: ".image-swiper-button-prev",
        }}
        modules={[Navigation]}
        id="carousel"
      >
        <SwiperSlide>
          {showBio && (
            <Bio
              showBio={showBio}
              setShowBio={setShowBio}
              title={Constants.BIO_SOFTWARE_ENGINEER_TITLE}
              text={Constants.BIO_SOFTWARE_ENGINEER_DESC}
              table={
                <div className="carousel-table">
                  <Tooltip
                    title={Constants.BIO_COOKULATOR_DESC}
                    placement="bottom-start"
                    followCursor
                  >
                    <Button
                      className="carousel-button"
                      style={{
                        color: "white",
                        textShadow: "1px 1px 2px black",
                        transition: "0.2s",
                        backgroundImage: `url(${cookulator})`,
                      }}
                      href="https://cookulatr.vercel.app/"
                      target="_blank"
                    >
                      cookulator
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={Constants.BIO_MONTOU_TIMER_DESC}
                    placement="bottom-start"
                    followCursor
                  >
                    <Button
                      className="carousel-button"
                      style={{
                        color: "white",
                        textShadow: "1px 1px 2px black",
                        transition: "0.2s",
                        backgroundImage: `url(${montoutimer})`,
                      }}
                      href="https://montou-timer.vercel.app/"
                      target="_blank"
                    >
                      montou timer
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={Constants.BIO_MICOQUIZ_DESC}
                    placement="bottom-start"
                    followCursor
                  >
                    <Button
                      className="carousel-button"
                      style={{
                        color: "black",
                        textShadow: "1px 1px 2px white",
                        transition: "0.2s",
                        backgroundImage: `url(${micoquiz})`,
                      }}
                      href="https://micoquiz.vercel.app/"
                      target="_blank"
                    >
                      micoquiz
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={Constants.BIO_SHANGCHI_DISCORD_BOT_DESC}
                    placement="bottom-start"
                    followCursor
                  >
                    <Button
                      className="carousel-button"
                      style={{
                        color: "white",
                        textShadow: "1px 1px 2px black",
                        transition: "0.2s",
                        backgroundImage: `url(${shangchi})`,
                      }}
                      href="https://github.com/rrtong/shang-chi-discord-ai/"
                      target="_blank"
                    >
                      shang-chi discord bot
                    </Button>
                  </Tooltip>
                </div>
              }
              footer={Constants.LINKS}
            ></Bio>
          )}
          <Panel
            showBio={showBio}
            setShowBio={setShowBio}
            background={stawamus}
            subtitle={Constants.BIO_SOFTWARE_ENGINEER_TITLE}
            footer={Constants.LINKS}
          />
        </SwiperSlide>
        <SwiperSlide>
          {showBio && (
            <Bio
              showBio={showBio}
              setShowBio={setShowBio}
              title={Constants.BIO_MARTIAL_ARTIST_TITLE}
              text={Constants.BIO_MARTIAL_ARTIST_DESC}
              table={
                <div className="carousel-table">
                  <div className="carousel-table-row">
                    <div>
                      <div>2023</div>
                      <div>6 months</div>
                    </div>
                    <div>muay thai </div>
                  </div>
                  <div className="carousel-table-row">
                    <div>
                      <div>2022-2023</div>
                      <div>6 months</div>
                    </div>
                    <div>taekwondo</div>
                  </div>
                  <div className="carousel-table-row">
                    <div>
                      <div>2022</div>
                      <div>3 months</div>
                    </div>
                    <div>muay thai</div>
                  </div>
                  <div className="carousel-table-row">
                    <div>
                      <div>2019</div>
                      <div>3 months</div>
                    </div>
                    <div>brazilian jiujitsu</div>
                  </div>
                  <div className="carousel-table-row">
                    <div>
                      <div>2016-2019</div>
                      <div>3 years</div>
                    </div>
                    <div>wing chun</div>
                  </div>
                </div>
              }
            ></Bio>
          )}

          <Panel
            showBio={showBio}
            setShowBio={setShowBio}
            background={stawamus_gungfu}
            subtitle={Constants.BIO_MARTIAL_ARTIST_TITLE}
          />
        </SwiperSlide>
        <SwiperSlide>
          {showBio && (
            <Bio
              showBio={showBio}
              setShowBio={setShowBio}
              title={Constants.BIO_GAMER_TITLE}
              text={Constants.BIO_GAMER_DESC}
              table={
                <div className="carousel-table">
                  <div className="carousel-table-row">
                    Approximate online-gaming timeline:
                  </div>
                  <div>
                    <div className="carousel-table-row">
                      <div>2000-2001</div>
                      <div>StarCraft: Brood War</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2001-2002</div>
                      <div>Gunbound</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2001-2004</div>
                      <div>RuneScape</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2003-2011</div>
                      <div>MapleStory</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2008-2012</div>
                      <div>Rumble Fighter</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2012-2014</div>
                      <div>Elsword</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2015-2016</div>
                      <div>Guild Wars 2</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2014-2020</div>
                      <div>League of Legends</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2020-2022</div>
                      <div>Genshin Impact</div>
                    </div>
                    <div className="carousel-table-row">
                      <div>2020-2022</div>
                      <div>Valorant</div>
                    </div>
                  </div>
                </div>
              }
            ></Bio>
          )}

          <Panel
            showBio={showBio}
            setShowBio={setShowBio}
            background={balldefender}
            subtitle={Constants.BIO_GAMER_TITLE}
          />
        </SwiperSlide>
        <SwiperSlide>
          {showBio && (
            <Bio
              showBio={showBio}
              setShowBio={setShowBio}
              title={Constants.BIO_HUMAN_TITLE}
              text={Constants.BIO_HUMAN_DESC}
            ></Bio>
          )}

          <Panel
            showBio={showBio}
            setShowBio={setShowBio}
            background={choihung}
            subtitle={Constants.BIO_HUMAN_TITLE}
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Carousel;
