import { FaGithub, FaLinkedin, FaFileAlt } from "react-icons/fa";
import { resume } from "../assets";

// links
export const LINKS = (
  <div className="carousel-footer">
    <a
      href="https://linkedin.com/in/rrtong"
      rel="noopener noreferrer"
      target="_blank"
    >
      <FaLinkedin />
    </a>
    <a
      href="https://github.com/rrtong"
      rel="noopener noreferrer"
      target="_blank"
    >
      <FaGithub />
    </a>
    <a href={resume} rel="noopener noreferrer" target="_blank">
      <FaFileAlt />
    </a>
  </div>
);

// bios
export const BIO_SOFTWARE_ENGINEER_TITLE = "SOFTWARE ENGINEER";
export const BIO_SOFTWARE_ENGINEER_DESC =
  "I am a software engineer with a passion in web development. Here are some of the projects I've done:";

export const BIO_COOKULATOR_DESC =
  "A conversion tool intended for complex calculations while baking and cooking.";
export const BIO_MONTOU_TIMER_DESC =
  "A tool made to calculate and automate Twitch giveaways based on set countdowns with variable decrements.";
export const BIO_MICOQUIZ_DESC =
  "An MVP for a method of quizzing that helped me study in university.";
export const BIO_SHANGCHI_DISCORD_BOT_DESC =
  "A discord chat bot that responds back with AI, solely using the entire script from the movie Shang-Chi and the Legend of the Ten Rings.";

export const BIO_MARTIAL_ARTIST_TITLE = "MARTIAL ARTIST";
export const BIO_MARTIAL_ARTIST_DESC =
  "I like to do martial arts, but these days I've transitioned to lifting weights at the gym.";

export const BIO_GAMER_TITLE = "GAMER";
export const BIO_GAMER_DESC =
  "I grew up playing Nintendo games and MMORPGs. After university, I transitioned to being a social gamer and mostly use it as a means to keep in touch with friends.";

export const BIO_HUMAN_TITLE = "HUMAN";
export const BIO_HUMAN_DESC =
  "It's crazy to think that we are only here and then we're not. At the end of the day, no matter how many titles one may have, we are all human. If I don't exist tomorrow then this serves as a reminder for you, if you're also human, to do what you want and seize today.";
